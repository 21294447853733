import {Content, Page} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useUser} from '../../common/hooks/useUser'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {UserInformationFormData} from '../../components/UserInformation/UserInformationForm.types'
import {useUpdateUser} from '../ManageUser/hooks/useUpdateUser'

import {InviteUser} from './components/InviteUser'
import {ResetPassword} from './components/ResetPassword'
import {UserDetailsField} from './components/UserDetailsField'
import {UserForm} from './components/UserForm'
import {Header} from './header/Header'
import {useStyles} from './QuickPreview.styles'
import {QuickPreviewSkeleton} from './QuickPreviewSkeleton'

export const QuickPreview = () => {
  const {t} = useTranslation()

  const {classes} = useStyles()

  const {userId} = useParams<{userId: string}>()

  const {
    data: user,
    refetch: refetchUser,
    isLoading: isUserLoading,
    isError: isUserError
  } = useUser(userId)

  const {data: roles, isLoading: isRolesLoading, isError: isRolesError} = useUserRoles(user?.id)

  const [isEditing, setEditing] = useState(false)

  const userForm = useMemo(
    () => ({
      name: user?.name ?? '',
      eMail: user?.eMail ?? '',
      mobileNumber: user?.mobileNumber ?? ''
    }),
    [user]
  )

  const formMethods = useForm<UserInformationFormData>({
    defaultValues: userForm,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    userForm && formMethods.reset(userForm)
  }, [formMethods, userForm])

  const {handleUpdateUser} = useUpdateUser({setEditing, formMethods, refetchUser, userData: user})

  const handleFormMode = (edit: boolean) => {
    setEditing(edit)
  }

  const handleCancel = () => {
    formMethods.reset(userForm)
  }

  if (isUserLoading || isRolesLoading) {
    return <QuickPreviewSkeleton />
  }

  if (isUserError || isRolesError) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        {t('error.notFound')}
      </Box>
    )
  }

  return (
    <Content>
      <Page variant="sheet" className={classes.page}>
        <Header userId={userId} isEditing={isEditing} handleFormMode={handleFormMode} />
        {user && (
          <UserForm
            user={user}
            formMethods={formMethods}
            handleUpdateUser={handleUpdateUser}
            handleCancel={handleCancel}
            isEditing={isEditing}
            toggleEdit={() => setEditing(!isEditing)}
            isUserLoading={isUserLoading}
          />
        )}
        <UserDetailsField roles={roles} isRolesLoading={isRolesLoading} />
        <InviteUser user={user} refetchUser={refetchUser} />
        <ResetPassword user={user} />
      </Page>
    </Content>
  )
}
