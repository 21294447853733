import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'


interface HeaderProps {
  userId: string
  isEditing: boolean
  handleFormMode: (edit: boolean) => void
}

export const Header = ({userId, isEditing, handleFormMode}: HeaderProps) => {
  const history = useHistory()

  const {t} = useTranslation()

  const handleRedirect = () => {
    history.push(`/manage/${userId}`)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
        <Typography variant="h3">{t('quickPreview.userQuickViewHeader')}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRedirect}
          data-test-id={`full-user-overview-btn-${userId}`}
          style={{backgroundColor: '#016AD4'}}
        >
          {t('quickPreview.fullUserOverview')}
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
        <Typography style={{fontWeight: 'bold'}}>{t('quickPreview.generalData')}</Typography>
        {!isEditing && (
          <Box mx={1}>
            <Button
              startIcon={<EditIcon style={{color: '#016AD4'}} />}
              variant="text"
              data-test-id="button-edit-userInfo"
              onClick={() => handleFormMode(true)}
              style={{color: '#016AD4'}}
            >
              {t('manageUser.edit')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
