import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block'
  },
  helperText: {
    color: theme.palette.secondary.main
  }
}))
