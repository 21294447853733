import {Product, User} from '@hconnect/apiclient'
import {Box, FormControlLabel, Switch, Tooltip, Typography} from '@mui/material'
import clsx from 'clsx'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useInviteUser} from '../hooks/useInviteUser'

import {useStyles} from './InviteUser.styles'

interface InviteUserProps {
  user?: User
  refetchUser: () => void
}

export const InviteUser = ({user, refetchUser}: InviteUserProps) => {
  const {inviteUser} = useInviteUser(user?.id, refetchUser)

  const [isHubInvited, setIsHubInvited] = useState(false)

  const [isOnSiteInvited, setIsOnSiteInvited] = useState(false)

  const {t} = useTranslation()

  const {classes} = useStyles()

  useEffect(() => {
    if (user) {
      setIsHubInvited(
        user?.invitationProducts?.some((product) => product.product === Product.Hub) ?? false
      )
      setIsOnSiteInvited(
        user?.invitationProducts?.some((product) => product.product === Product.OnSite) ?? false
      )
    }
  }, [user])

  const getLastActivityDate = (clientId: Product) => {
    const activity = user?.lastActivityDates?.find((date) => date.clientId === clientId)
    return activity ? moment(activity.lastActivityDate).format('DD/MM/YYYY') : 'N/A'
  }

  const handleSwitchChange = async (product: Product, isInvited: boolean): Promise<void> => {
    if (!isInvited) {
      await inviteUser(product)
    }
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
        <Typography style={{fontWeight: 'bold'}}>{t('quickPreview.productInvited')}</Typography>
        <Typography style={{fontWeight: 'bold'}}>{t('quickPreview.lastActivityDate')}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <FormControlLabel
          value="top"
          disabled={isHubInvited}
          control={
            <Tooltip title={isHubInvited ? t('quickPreview.hubCannotUnInvite') : ''}>
              <span>
                <Switch
                  color="primary"
                  checked={isHubInvited}
                  disabled={isHubInvited}
                  onChange={() => handleSwitchChange(Product.Hub, isHubInvited)}
                  data-test-id={`invite-product-${Product.Hub}`}
                  classes={{
                    track: clsx({
                      [classes.trackEnabled]: isHubInvited
                    })
                  }}
                />
              </span>
            </Tooltip>
          }
          label={Product.Hub}
          labelPlacement="end"
          aria-readonly={isHubInvited}
          data-test-id={`invite-product-${Product.Hub}-label`}
        />
        <Typography>{getLastActivityDate(Product.Hub)}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <FormControlLabel
          value="top"
          disabled={isOnSiteInvited}
          control={
            <Tooltip title={isOnSiteInvited ? t('quickPreview.onsiteCannotUnInvite') : ''}>
              <span>
                <Switch
                  color="primary"
                  checked={isOnSiteInvited}
                  disabled={isOnSiteInvited}
                  onChange={() => handleSwitchChange(Product.OnSite, isOnSiteInvited)}
                  data-test-id={`invite-product-${Product.OnSite}`}
                  classes={{
                    track: clsx({
                      [classes.trackEnabled]: isOnSiteInvited
                    })
                  }}
                />
              </span>
            </Tooltip>
          }
          label={Product.OnSite}
          labelPlacement="end"
          aria-readonly={isOnSiteInvited}
          data-test-id={`invite-product-${Product.OnSite}-label`}
        />
        <Typography>{getLastActivityDate(Product.OnSite)}</Typography>
      </Box>
    </Box>
  )
}
