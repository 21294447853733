import {UserCategory} from './UsersList.enums'

export type UsersTableFilter = {
  rowsPerPage: number
  page: number
  sortColumn: string
  sortDirection: 'desc' | 'asc'
  name?: string
  marketId?: string
  roleType?: string
  userCategory: UserCategory
  country?: string
  userIssue: UserIssueKeys
  createdBy?: boolean
  hideTestUser?: boolean
  isInternal?: boolean
  isTester?: string
  lastActivityProduct?: string
  customerId?: string
  payerId?: string
  contractId?: string
  plantId?: string
  eMail?: string
  businessLine?: string
  companyName?: string
  mobileNumber?: string
  createdOn?: string
  branding?: string
  blocklistNotifications?: string
}

export type RoleOption = {
  label: string
  value: string
}

export enum UserIssueKeys {
  ALL = 'all',
  IS_INVITE_SENT = 'isInvitationSent',
  NO_PSW = 'noPsw',
  HAS_ROLES = 'hasRoles',
  NEVER_LOGGED_IN = 'neverLoggedIn',
  IS_LOCKED_OUT = 'isLockedOut',
  FAILED_LOGIN = 'failedLogin'
}
