import {User} from '@hconnect/apiclient'
import {Box, Button} from '@mui/material'
import React from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {UserEmailInput} from '../../../components/UserInformation/inputs/UserEmailInput'
import {UserMobileNumberInput} from '../../../components/UserInformation/inputs/UserMobileNumberInput'
import {UserNameInput} from '../../../components/UserInformation/inputs/UserNameInput'
import {UserInformationFormData} from '../../../components/UserInformation/UserInformationForm.types'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../../modules/LoggedInUser.selectors'

interface UserFormProps {
  user: User
  formMethods: UseFormReturn<UserInformationFormData, object>
  handleUpdateUser: (data: UserInformationFormData) => void
  handleCancel: () => void
  isEditing: boolean
  toggleEdit: () => void
  isUserLoading: boolean
}

export const UserForm = ({
  user,
  formMethods,
  handleUpdateUser,
  handleCancel,
  isEditing,
  toggleEdit,
  isUserLoading
}: UserFormProps) => {
  const {t} = useTranslation()

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const permissions = useSelector(selectLoggedInUserPermissions)

  const canEditUserEmailPhone =
    loggedInUserProfile?.id === user?.id ||
    permissions.some((permission) => permission.permissionType === 'CHANGE_USER_LOGIN')

  const discardChanges = () => {
    handleCancel()
    toggleEdit()
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleUpdateUser)}>
      <UserNameInput
        readonlyMode={!isEditing}
        formMethods={formMethods}
        isLoading={isUserLoading}
      />
      <UserEmailInput
        readonlyMode={!isEditing}
        formMethods={formMethods}
        canEditUserEmailPhone={canEditUserEmailPhone}
        isLoading={isUserLoading}
        setShowMfaInput={() => {}}
      />
      <UserMobileNumberInput
        readonlyMode={!isEditing}
        formMethods={formMethods}
        canEditUserEmailPhone={canEditUserEmailPhone}
        isLoading={isUserLoading}
        userMobileNumber={user?.mobileNumber}
      />
      {isEditing && (
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={discardChanges}
            disabled={formMethods.formState.isSubmitting}
            style={{color: '#016AD4'}}
          >
            {t('quickPreview.discardChanges')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={formMethods.formState.isSubmitting}
            style={{backgroundColor: '#016AD4'}}
          >
            {t('quickPreview.saveChanges')}
          </Button>
        </Box>
      )}
    </form>
  )
}
